import React from 'react'
import { Auth } from 'aws-amplify'

import { Form, Button, Message } from 'semantic-ui-react'

class NewPassword extends React.Component {
    state = {
        password: '',
        repeatPassword: '',
        errorMessage: '',
        loading: false,
        passwordError: false,
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })

    submit = () => {
        this.setState({
            loading: true,
            passwordError: false,
            errorMessage: false,
        })
        const { password, repeatPassword } = this.state

        if (password !== repeatPassword) {
            this.setState({
                errorMessage: "Passwords don't match",
                loading: false
            })
            return
        }
        Auth.completeNewPassword(this.props.user, this.state.password, {
          email: this.props.username,
        }).then((user) => {
            this.setState({
                loading: false
            })
          this.props.successHandler(user)
        }).catch((error) => {
            if (error.code === 'InvalidPasswordException') {
                this.setState({
                    passwordError: true
                })
            } else {
                console.log(error)
                this.setState({
                    errorMessage: error.message,
                })
            }
            this.setState({
                loading: false
            })
        })
    }

    render() {
        return (
            <Form loading={this.state.loading}>
                <h4>You're required to set a new password.</h4>
                <Message
                    error
                    visible={this.state.passwordError}
                    header="Your password doesn't conform to the password policy"
                    list={[
                        'Password has to contain at least 8 characters',
                        'You must include both a upper and lower case letters in your password.',
                        'You must include at least one number in your password',
                        'You must include at least one special character in your password',
                    ]}
                />
                <Message error visible={!!this.state.errorMessage} content={this.state.errorMessage} />
                <Form.Input name='password' label='Password' type='password' value={this.state.password} onChange={this.handleChange} />
                <Form.Input name='repeatPassword' label='Repeat Password' type='password' value={this.state.repeatPassword} onChange={this.handleChange} />
                <Button onClick={this.submit} type='submit'>Submit</Button>
            </Form>
        )
    }
}

export default NewPassword
