
    const awsConfig = {
        "stage": "prod",
        "aws_project_region": "eu-west-1",
        "aws_cognito_region": "eu-west-1",
        "aws_user_pools_id": "eu-west-1_QKw5O6uIG",
        "aws_user_pools_web_client_id": "7lrbtmeo6ebhh6ti27rrkcimt4",
        "aws_cloud_logic_custom": [
            {
                "name": "backend",
                "endpoint": "https://g43puw4gzg.execute-api.eu-west-1.amazonaws.com/"
            }
        ]
    }
    export default awsConfig
    