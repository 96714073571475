import React from 'react'
import { Auth, API } from 'aws-amplify'
import UserContext from './UserContext'
import Container from './Container'
import { Button, Search, Form, Dimmer, Loader, Segment} from 'semantic-ui-react'


class Promo extends React.Component {
  state = {
    qrCode: '',
    challengeAnswer: '',
    showPreferred: false,
    title: '',
    tag: '',
    suggestions: [],
    queryMetadata: '',
    recs: [],
    resultsLoading: false,
    titles: [],
    tags: [],
    titleResults: [],
    tagResults: [],
    objectsLoading: false
  }
  static contextType = UserContext

  async componentDidMount() {
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    }
    this.setState({
      objectsLoading: true,
    })
    const titlesPromise = (await API.get('backend', '/titles', myInit))
    const tagsPromise = (API.get('backend', '/tags', myInit))
    const [titles,  tags] = await Promise.all([titlesPromise, tagsPromise])
    this.setState({
      objectsLoading: false,
      titles: titles.objects,
      tags: tags.objects,
    })
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue
    });
  }

  submit = async (type) => {
    const instances = this.state[type]
    if (!instances) {
      return
    }
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: {
        instances
      }
    }
    this.setState({
      resultsLoading: true
    })
    try {
      const clusters = (await API.post('backend', `/${type}`, myInit))
      this.setState({
        queryMetadata: JSON.stringify(clusters.query),
        recs: clusters.rankedClusters
      })
    } finally {
      this.setState({
        resultsLoading: false
      })
    }

  }

  getTitleSuggestions = (e, data) => {
    this.setState({
      title: data.value
    })
    const escapedValue = data.value.trim()

    if (escapedValue === '') {
      return []
    }

    const regex = new RegExp('^' + escapedValue, 'i')
    const titles = this.state.titles.filter(title => regex.test(title)).slice(0, 50)
    this.setState({
      titleResults: titles.map(title => ({ title }))
    })
  }

  getTagSuggestions = (e, data) => {
    this.setState({
      tag: data.value
    })
    const escapedValue = data.value.trim()

    if (escapedValue === '') {
      return []
    }

    const regex = new RegExp('^' + escapedValue, 'i')
    const tags = this.state.tags.filter(tag => regex.test(tag)).slice(0, 50)
    this.setState({
      tagResults: tags.map(tag => ({ title: tag }))
    })
  }

  render() {
    return (
        <Container>
          <h1>Cluster Recommendation for Promo</h1>
          {
            (
                <Segment>
                  <Dimmer active={this.state.resultsLoading} inverted>
                    <Loader content='Loading' inverted/>
                  </Dimmer>
                  <Form>
                    <Dimmer active={this.state.objectsLoading} inverted>
                      <Loader content='Loading' inverted/>
                    </Dimmer>
                    <h3>Search for title</h3>
                    <Form.Group inline>
                      <Form.Field>
                        <Search
                            onResultSelect={(e, data) => {
                              this.setState({title: data.result.title})
                            }}
                            onSearchChange={this.getTitleSuggestions}
                            results={this.state.titleResults}
                            value={this.state.title}
                        />
                      </Form.Field>
                      <Button loading={this.state.resultsLoading} disabled={this.state.resultsLoading} onClick={this.submit.bind(this, 'title')} primary>
                        Submit
                      </Button>
                    </Form.Group>
                    <h3>Search for keyword</h3>
                    <Form.Group inline>
                      <Form.Field>
                        <Search
                            onResultSelect={(e, data) => {
                              this.setState({tag: data.result.title})
                            }}
                            onSearchChange={this.getTagSuggestions}
                            results={this.state.tagResults}
                            value={this.state.tag}
                        />
                      </Form.Field>
                      <Button onClick={this.submit.bind(this, 'tag')} primary>
                        Submit
                      </Button>
                    </Form.Group>
                  </Form>

                  <Form>

                  </Form>
                  <h3>Seed Metadata</h3>
                  <p>
                    {this.state.queryMetadata}
                  </p>

                  <h3>Recommendations</h3>
                  <ul>
                    {this.state.recs.map((rec, index) => {
                      return <li key={index}>{index}: {JSON.stringify(rec)}</li>
                    })}
                  </ul>
                </Segment>
            )
          }
        </Container>
    )
  }
}

export default Promo
